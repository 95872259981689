<template>
    <div class="wrap">
        <div class="facilityBox">
            <!-- form表单 -->
            <el-form class="manageForm" :model="manageForm" :inline="true">
                <el-form-item label="企业名称：" prop="EnterpriseFullName">
                    <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
                        @keyup.enter.native="search()"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="LegalPersonlPhone">
                    <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"
                        @keyup.enter.native="search()"></el-input>
                </el-form-item>
                <el-form-item label="提交日期：" prop="TaskDatetime">
                    <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至"
                        start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期"
                        @change="searchChange()"></el-date-picker>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                    <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="LegalPersonIName" label="法人姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Status" label="审核状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="success">{{ scope.row.Status }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ConfigStatus" label="设置状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="success">{{ scope.row.ConfigStatus }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="ProviderStatus" label="同意状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="warning">{{ scope.row.ProviderStatus }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="CommissionType" label="分成模式" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div style="color:#2081FF;">{{ scope.row.CommissionType }}</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="420">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-setting" @click="examineRate(scope.row)">
                            查看费率</el-button>
                        <el-button type="success" size="small" @click="agreeClick(scope.row)"
                            icon="el-icon-circle-check">同意</el-button>
                        <el-button type="danger" size="small" @click="backClick(scope.row, 'reject')"
                            icon="el-icon-refresh-right">退回重设</el-button>
                        <el-button type="primary" size="small" @click="goFacility(scope.row, pagination.page)"
                            icon="el-icon-tickets">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top:10px">
                <!-- 分页 -->
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
            </div>
        </div>
        <!-- 驳回弹窗 -->
        <el-dialog title="驳回意见" :visible.sync="flag.centerDialogVisible" width="30%" center>
            <span>驳回 [客服] {{ modelData.AssignUserName }}：</span>
            <div style="margin:20px 0 0 0;">
                <el-input type="textarea" :rows="6" maxlength="100" show-word-limit placeholder="请输入驳回处理意见，限制100字。"
                    v-model="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="flag.centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="bindBack()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看费率 -->
        <el-dialog :visible.sync="flag.rateMdel" width="700px">
            <span slot="title">查看费率</span>
            <div class="dialog_box">
                <div class="dialogRole">
                    <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
                </div>
                <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
                    max-height="500px">
                    <el-table-column align="center" label="发票-业务-场景">
                        <template slot-scope="scope">
                            <div v-if="scope.row.TaskSceneName">
                                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{ scope.row.TaskSceneName }}
                            </div>
                            <div v-else>
                                {{ scope.row.InvoiceTypeName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="Rate" label="数值" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="flag.rateMdel = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { BySupplierList, getPlatFormBy } from "@/api/auditInfo/certified/index";
import {
    ConfirmCommission,
    BackCommission,
} from "@/api/auditInfo/auditOwner/index";
export default {
    data() {
        return {
            // 分页参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            // 筛选条件数据
            manageForm: {
                EnterpriseFullName: "",
                LegalPersonlPhone: "",
                TaskDatetime: ["", ""],
            },
            // 数据列表
            tableData: [],
            // 数据请求参数
            ConfigStatus: 0,
            // 单条数据id
            CustomerID: "",
            // 发票类型
            CommissionType: "01",
            // 弹窗集合
            flag: {
                // 是否显示退回意见弹窗
                centerDialogVisible: false,
                // 是否禁用保底分成模式
                minimum: true,
                // 查看费率
                rateMdel: false
            },
            // 退回意见文本
            textarea: "",
            // 退回意见集合
            modelData: "",
            // 当前被查看数据的ID
            CustomerID: "",
            // 选中的分成模式
            dialogName: "first",
            // 驳回状态
            backType: "",
            // 表格loading
            loading: true,
            // 服务商信息
            serveInfo: '',
            // 费率信息
            rateData: ''
        };
    },
    created() {
        // 获取数据列表
        this.BySupplierList();
    },
    methods: {
        searchChange() {
            this.search()
        },
        // 查看费率
        examineRate(item) {
            this.serveInfo = item
            getPlatFormBy({ UserID: item.UserID }).then(res => {
                let ratelist = [
                    {
                        InvoiceTypeName: '平台技术服务费费率',
                        Rate: `${res.eInvoiceTypeList[0].Rate}%`
                    }, {
                        InvoiceTypeName: '每月可开票最大金额',
                        Rate: res.eInvoiceTypeList[0].TotalInvoiceAmount
                    }
                ]
                res.eInvoiceTypeList.forEach(item => {
                    item.Rate = `${item.RateMinimun}%`
                    ratelist.push(item)
                })
                this.rateData = ratelist
                this.flag.rateMdel = true;
            })
        },
        // 提交退回意见
        bindBack() {
            let BackType = ''
            if (this.backType == "reject") {
                // 未同意退回重设
                BackType = 0
            } else {
                // 已同意退回重设
                BackType = 1
            }
            if (this.textarea) {
                let data = {
                    CustomerID: this.modelData.UserID,
                    BackType,
                    Remark: `驳回意见：${this.textarea}`,
                };
                BackCommission({ Json: JSON.stringify(data) }).then((res) => {
                    let data = {
                        EnterpriseFullName: this.manageForm.EnterpriseFullName,
                        LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                        SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                        SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                        pageSize: this.pagination.pagesize,
                        pageIndex: this.pagination.page,
                        ProviderStatus: this.ConfigStatus,
                        ConfigStatus: 1,
                    };
                    this.BySupplierLists(data);
                    this.flag.centerDialogVisible = false;
                    this.textarea = "";
                });
            } else {
                this.$message.error("请输入驳回意见");
            }
        },
        // 退回重设
        backClick(item, type) {
            if (type == "sendback") {
                this.flag.rateMdel = false;
            }
            this.backType = type;
            this.modelData = item;
            this.flag.centerDialogVisible = true;
        },
        // 同意合作分成比例
        agreeClick(item) {
            let data = {
                CustomerID: item.UserID,
            };
            ConfirmCommission({ Json: JSON.stringify(data) }).then((res) => {
                let data = {
                    EnterpriseFullName: this.manageForm.EnterpriseFullName,
                    LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                    SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                    SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                    pageSize: this.pagination.pagesize,
                    pageIndex: this.pagination.page,
                    ProviderStatus: this.ConfigStatus,
                    ConfigStatus: 1,
                };
                this.BySupplierLists(data);
            });
        },
        // 清空搜索条件
        resetForm() {
            this.manageForm = {
                EnterpriseFullName: "",
                LegalPersonlPhone: "",
                TaskDatetime: ["", ""],
            };
            let data = {
                EnterpriseFullName: this.manageForm.EnterpriseFullName,
                LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                pageSize: this.pagination.pagesize,
                pageIndex: 1,
                ProviderStatus: this.ConfigStatus,
                ConfigStatus: 1,
            };
            this.BySupplierLists(data);
        },
        // 搜索
        search() {
            let data = {
                EnterpriseFullName: this.manageForm.EnterpriseFullName,
                LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                pageSize: this.pagination.pagesize,
                pageIndex: 1,
                ProviderStatus: this.ConfigStatus,
                ConfigStatus: 1,
            };
            this.BySupplierLists(data);
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e;
            let data = {
                EnterpriseFullName: this.manageForm.EnterpriseFullName,
                LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                pageSize: this.pagination.pagesize,
                pageIndex: e,
                ProviderStatus: 0,
                ConfigStatus: 1,
            };
            this.BySupplierLists(data);
        },
        // 获取数据列表
        BySupplierList() {
            let data = {
                EnterpriseFullName: this.manageForm.EnterpriseFullName,
                LegalPersonlPhone: this.manageForm.LegalPersonlPhone,
                SubmitDatetimeStart: this.manageForm.TaskDatetime[0],
                SubmitDatetimeEnd: this.manageForm.TaskDatetime[1],
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                ProviderStatus: this.ConfigStatus,
                ConfigStatus: 1,
            };
            this.BySupplierLists(data);
        },
        // 设置服务商分成比例列表
        BySupplierLists(data) {
            this.loading = true
            BySupplierList({ Json: JSON.stringify(data) }).then((res) => {
                this.tableData = res.enterpriseBase;
                this.pagination.total = Number(res.totalRowCount);
            }).finally(() => {
                this.loading = false;
            })
        },
        // 查看详情
        goFacility(item, pageIndex) {
            this.$router.push({
                path: "/auditInfo/agreeService/agreeInfo",
                query: {
                    UserID: item.UserID,
                    pageIndex
                },
            });
        },
    },
};
</script>

<style lang="scss">
.wrap {
    .el-input.is-disabled .el-input__inner {
        color: #333 !important;
    }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 20px 0 0 0;
}

.mdoeDiv {
    font-size: 16px;
    width: 80%;
    margin: 10px 10% 0;
    font-weight: bold;
    color: red;
}

.dialog_box {
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    background: #f8f8f8;
    padding: 20px 5%;
    box-sizing: border-box;
    color: #666;
    border-radius: 5px;

    .dialogRole {
        width: 100%;
        text-align: center;
    }
}

.deep_dialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
        border-top: 1px solid #eee;
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        padding: 0px 20px 20px;
    }
}
</style>